import React from "react"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Box, Text, Image, Divider } from "theme-ui"
import Blockquote from "./Blockquote"

export default function MdToReact({ content }) {
  return (
    <Box>
      {documentToReactComponents(content, {
        renderMark: {
          [MARKS.BOLD]: text => <Text as="span" variant="button">{text}</Text>,
        },
        renderNode: {
          [BLOCKS.HEADING_1]: (_node, children) => <Text as="h1" variant="title01">{children}</Text>,
          [BLOCKS.HEADING_2]: (_node, children) => <Text as="h2" variant="title02" sx={{ mt: 4}}>{children}</Text>,
          [BLOCKS.HEADING_3]: (_node, children) => <Text as="h3" variant="title03" sx={{ mt: 4}}>{children}</Text>,
          [BLOCKS.HEADING_4]: (_node, children) => <Text as="h4" variant="title04" sx={{ mt: 3}}>{children}</Text>,
          [BLOCKS.PARAGRAPH]: (_node, children) => <Text as="p" variant="body" sx={{ mb: 2 }}>{children}</Text>,
          [BLOCKS.HR]: (node, children) => <Divider />,
          [BLOCKS.QUOTE]: (_node, children) => <Blockquote quote={children} />,
          [INLINES.HYPERLINK]: (node, children) => {
            return (
              <Text as="a" variant="link" href={node.data.uri} target={node.data.uri.includes("adventure.game") ? "_self" : "_blank"} rel={node.data.uri.includes("adventure.game") ? "noreferrer" : null}>{children}</Text>
            )
          },
          "embedded-asset-block": node => {
            const alt = node.data.target.fields.title["en-US"]
            const url = node.data.target.fields.file["en-US"].url
            return <Image alt={alt} src={url} sx={{ width: "100%", my: 5 }} />
          },
        },
      })}
    </Box>
  )
}