import React from "react"
import { graphql } from "gatsby"
import { Grid, Box, Text } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"
import MdToReact from "../components/MdToReact"
import SupportForm from "../components/forms/SupportForm"

export const query = graphql`
  query {
    contentfulFooterPage(slug: {eq: "support"}) {
      title
      slug
      body {
        json
      }
    }
  }
`

const SupportPage = props => { 
  return (
    <Layout>
      <SEO title={props.data.contentfulFooterPage.title} />

      <Breadcrumbs title={props.data.contentfulFooterPage.title} />

      <Grid variant="textWrap">
        <Box>
          <MdToReact content={props.data.contentfulFooterPage.body.json} />
          
            {/* <SupportForm /> */}

            {/* <Text as="p" variant="body">Note: we will only use the information you provide in this form to respond directly to your request. We won’t share your email address or personal information except when necessary to resolve an issue with an order.</Text>. */}
        </Box>
      </Grid>
    </Layout>
  )
}

export default SupportPage
